.drawer {
  width: max-content;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0 2rem 2rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}

.drawer button {
  position: absolute;
  left: 0;
  padding: 0;
  margin: 0 0.5rem;
  background: none;
  border: none;
}

.drawer img {
  margin: 3rem 0 1.5rem 0;
  width: 50%;
}

.routes {
  width: 100%;
  height: 100%;
}

.row-option {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.row-option svg {
  width: 3rem;
  transform: scale(1.25);
  color: #646464;
}

.row-option h1 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #646464;
  margin: 0;
  padding: 0;
}

.row-option.isActive > svg {
  color: #6FB9FF
}

.row-option.isActive > h1 {
  color: #000
}

.panel { /* this will just hold the drawer ui */
  position: relative;
  width: min-content;
  height: 100%;
}

.panel button {
  background: none;
  border: none;
}

.panel svg {
  width: 3rem;
  transform: scale(1.25);
  margin: 1rem 0;
}

@media only screen and (max-width: 600px) { /* mobile */
  .panel { /* this will just hold the hamburger icon button in a header-bar*/
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
  }

  .absolute {
    z-index: 99;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transition: 1s ease-in-out;
  }

  .drawer {
    min-width: 75%;
  }
}
